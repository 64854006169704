import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Share from "./components/share/share";
// import { triggerHKApi } from "../api/Auth";
import { getTriageResult } from "../constant/etriage/etriage";
import { save, load, getSurveyByClientId } from "../api/survey";
import { updateCouponWithClientId, getFreeCoupons } from "../api/coupon";
import { getClientPolicy } from "../api/godp"
import { makeStyles } from "@material-ui/core";
import { COLORS } from "../constant/colors";
import nextIconSvg from "../assets/eTriage/result/nextIcon.svg";


const useStyles = makeStyles((theme) => ({


  downloadThoughtFullApp: {
    borderTop: `1px solid ${COLORS.fwdGrey}`,
    padding: "20px 0px",
    lineHeight: "18px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  downloadThoughtFullAppButton: {
    color: COLORS.fwdOrange,
    fontSize: 14,
    fontWeight: 700,
    lineHeight: "18px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    cursor: "pointer",
  },

}));

function Survey() {
  const classes = useStyles();

  const { t } = useTranslation();
  const [key, setKey] = useState("");
  const [answers, setAnswers] = useState([]);
  const [addResult, setaddResult] = useState('');
  const [clientId, setClientId] = useState('');
  const [ifPolicy, setIfPolicy] = useState('');
  const [coupons, setCoupons] = useState([])
  const [clientSurvey, setClientSurvey] = useState({});
  const result = getTriageResult(4, 19, 8);


  const downloadThoughtFullOnClicked = () => {
    const thoughtfullAppLink = `${process.env.REACT_APP_THOUGHTFULL_APP_LINK_GENERAL
      }?${'drmfsltd'}`;
    navigator.clipboard.writeText("pppp").then(() => {
      window.open(thoughtfullAppLink);
    });

    // window.open(thoughtfullAppLink);
  };


  return (
    <div className="App">
      <header className="App-header">
        {/* <img src={logo} className="App-logo" alt="logo" /> */}
        <p>
          TESTING PAGE Survey!!!! Edit <code>src/App.js</code> and save to
          reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React {t("title")}
        </a>
      </header>

      <div
        onClick={() => {
          // triggerHKApi();
        }}
      >
        Call HK Api
      </div>
      <Share label="Share ABC" title="yoyhhoyoooo" text="Hellow world!" />
      <br />
      <br />
      <button onClick={async () => {
        setClientSurvey(await getSurveyByClientId('10002311'))
      }}>getSurveyByClientId</button>
      <p>Coupons:{clientSurvey.answers}</p>
      <br /><br />
      <button onClick={async () => {
        const clientInfo = await getClientPolicy('790101071137', '19790101')
        console.log(clientInfo)
        if (clientInfo.code === 200 || clientInfo.code === 202) {
          setClientId(clientInfo.clientId)
          setIfPolicy(clientInfo.hasPolicy.toString())
        } else {
          setClientId(clientInfo.code)
          setIfPolicy(clientInfo.message)
        }

      }}>Get Client Policy</button>
      <ul>
        <li>
          clientId: {clientId}
        </li>
        <li>
          hasPolicy: {ifPolicy}
        </li>
      </ul>
      <br /><br />
      <button onClick={async () => {
        setCoupons(await getFreeCoupons())
      }}>Get Free Coupons</button>
      <p>Coupons:{coupons.length}</p>
      {coupons.map((value) => {
        return (
          <ul>
            <li>
              clientId: {value.clientId}
            </li>
            <li>
              coupon: {value.coupon}
            </li>
            <li>
              isUsed: {value.isUsed.toString()}
            </li>
            <li><button onClick={async () => {
              let result = await updateCouponWithClientId('peggy')
              setaddResult(result)
              setCoupons(await getFreeCoupons())
            }}>Assign Client</button></li>
          </ul>
        );
      })}
      <br />
      <p>Your Coupon Code: {addResult}</p>
      <br />
      <ul>
        <li>PHQ-9: {result["PHQ-9"]}</li>
        <li>GAD-7: {result["GAD-7"]}</li>
        <li>WSAS: {result["WSAS"]}</li>
        <li>Result: {result["Result:"]}</li>
        <li>Total Level: {result["Total level"]}</li>
      </ul>
      <br />
      <br />
      <button
        onClick={async () => {
          setKey(await save([1, 0, 2, 2, 3, 0], 'klauswong', true));
        }}
      >
        Save Answers 1,0,2,2,3,0 To Dynamodb
      </button>
      <br />

      <p>key: {key}</p>
      <br />

      <button
        onClick={async () => {
          setAnswers(await (await load(key)).answers);
        }}
      >
        Load Answers From Dynamodb
      </button>

      <ul>
        <li>Answers</li>
        {answers.map((value, i) => {
          return (
            <li>
              question {value['question']}: {value['answer']}
            </li>
          );
        })}

      </ul>





      <br />
      <br />

      <div className={classes.downloadThoughtFullApp}>
        <div
          className={classes.downloadThoughtFullAppButton}
          onClick={() => {
            downloadThoughtFullOnClicked();
          }}
        >
          <img src={nextIconSvg} />
          {"Try copy abc and download TF app"}
        </div>
      </div>
      <br />
      123
    </div>
  );
}

export default Survey;
